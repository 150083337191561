import { ProjectProps } from "containers/Projects";
import ProjectView from "containers/ProjectView";
import React from "react";
import image1 from "images/mtc/image-1.jpg";
import image2 from "images/mtc/image-2.jpg";
import image3 from "images/mtc/image-3.jpg";
import image4 from "images/mtc/image-4.jpg";
import logo from "images/mtc/logo.jpg";

const slides = [
  {
    name: "General",
    image: image1,
    text: (
      <div>
        <h2>Powerful Tool for Virtual Health</h2>A fully functional tool for a
        Californian therapy network. Daily access of over 500 users to manage
        their therapy sessions, personal information, and contact with medical
        professionals.
      </div>
    ),
  },
  {
    name: "UI/UX",
    image: image2,
    text: (
      <div>
        <h2>User Friendly Approach</h2>
        Harnasses the full power of SQL, React, and Redux to present a powerful,
        responsive, and user-friendly UI/UX experience.
      </div>
    ),
    style: {
      background: "var(--bg-darker)",
      color: "white",
    },
  },
  {
    name: "Design",
    image: image3,
    text: (
      <div>
        <h2>From Concept to Creation</h2>
        Built functional components from the ground up based on the client's
        InVision design.
      </div>
    ),
    style: {
      background: "var(--bg-blue-muted)",
      color: "white",
    },
  },
  {
    name: "Feature",
    image: image4,
    text: (
      <div>
        <h2>Flexible API</h2>
        Developed a flexible api backend to suit all users needs. API also
        integrates with SignNow for secure and legal signing of documents.
      </div>
    ),
    style: {},
  },
];
const badges = [
  "postgres",
  "nodejs",
  "sails",
  "react",
  "signnow",
  "docker",
  "azure",
];

const MTC: React.FC<ProjectProps> = (props) => (
  <ProjectView {...props} logo={logo} slides={slides} techs={badges} />
);

export default MTC;
