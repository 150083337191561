export default class Wall {
  x: number;
  y: number;
  width: number;
  height: number;
  scored: boolean;

  constructor(x: number, width: number) {
    this.x = x;
    this.y = -40;
    this.width = width;
    this.height = 40;
    this.scored = false;
  }

  get left() {
    return this.x;
  }
  get right() {
    return this.x + this.width;
  }
  get top() {
    return this.y;
  }
  get bot() {
    return this.y + this.height;
  }
}
