import { GAME_HEIGHT, GAME_WIDTH } from "./constants";
import React, { useEffect, useRef } from "react";

import Game from "engine/Game";
import styled from "styled-components";

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
`;

const Content = styled.div`
  width: ${GAME_WIDTH}px;
  height: ${GAME_HEIGHT}px;
  margin: 120px auto;
  background: black;
  z-index: 100;
`;

interface Props {}

const GameModal: React.FC<Props> = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>();
  const gameRef = useRef<Game | null>();

  useEffect(() => {
    if (canvasRef.current && !gameRef.current) {
      gameRef.current = new Game(canvasRef.current);
    }
  }, [canvasRef, gameRef]);

  return (
    <Modal>
      <Content>
        <canvas ref={(el) => (canvasRef.current = el)} id="game-canvas" />
      </Content>
    </Modal>
  );
};

export default GameModal;
