import FadeInOut from "animations/FadeInOut";
import { Link } from "react-router-dom";
import React from "react";
import { device } from "utils/device";
import headshot from "images/me/headshot.jpg";
import styled from "styled-components";

interface Props {}

const Container = styled.div`
  width: 64%;
  min-width: 600px;
  max-width: 800px;
  max-height: 90%;
  box-sizing: border-box;
  background: white;
  color: black;
  font-size: 20px;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media ${device.tablet} {
    width: 100%;
    margin: 0;
    min-width: auto;
  }

  > p {
    margin: 20px 0px;
  }
`;

const ImageContainer = styled.div`
  float: right;
  width: 200px;
  height: 200px;
  overflow: hidden;
  position: relative;
  border-radius: 100px;
`;

const Headshot = styled.img`
  position: absolute;
  top: -130px;
  left: -8px;
  width: 120%;
`;

const Content = styled.div`
  width: 100%;
  padding: 20px 36px;
`;

const About: React.FC<Props> = () => {
  return (
    <Container className="headline shadow-4">
      <FadeInOut>
        <Content>
          <h1>About</h1>
          <div>
            <ImageContainer>
              <Headshot src={headshot} />
            </ImageContainer>
            <p>
              Hi! My name Daniel Cardenas. I am an actor, teacher, and software
              developer. I strive to make everything I do engaging, fun and
              highly functional. My interest in development started as a child,
              creating ezboards, visual basic applications, and websites using
              Microsoft Frontpage. I would spend hours tweaking and creating
              sites for my childhood fandoms and passion for mathematics.
            </p>
          </div>
          <p>
            As I grew, my interest in music and performing developed, so I
            graduated with a degree in music theater from Arizona State
            University. This led me on quite an exciting journey, performing
            across the country and the world in many wonderful productions. My
            passion for math and technology remained, as I returned to those
            fields around 2016.
          </p>
          <p>
            Initially, I tutored students in math and coding (Scratch, Python,
            C, Java, JavaScript, HTML and so on) in New York City. I branched
            that out into working on freelance development projects as well as
            some of my personal projects, which I hope you take some time to
            explore. If there is anything else wou would like to know, feel free
            to <Link to="/contact">contact</Link> me! Thanks!
          </p>
        </Content>
      </FadeInOut>
    </Container>
  );
};

export default About;
