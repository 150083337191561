import React from 'react';
import CSS from 'csstype';
import styled from 'styled-components';
import { badges } from './badges';

export interface BadgeStyles {
  container?: CSS.Properties,
  imageContainer?: CSS.Properties,
  image?: CSS.Properties,
  textContainer?: CSS.Properties,
}

export interface BadgeProps {
  type: string
}

const size = 100;

const Container = styled.div`
  width: ${size}px;
  text-align: center;
  border-radius: 10px;
  box-shadow: var(--shadow-1);
  display: inline-block;
  margin: 4px 10px;
  transition: 0.2s all ease-in-out;

  :hover {
    transform: scale(1.1);
    box-shadow: var(--shadow-3);
  }
`

const ImageContainer = styled.div`
  background: white;
  height: ${size}px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
`

const Image = styled.img`
  width: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const TextContainer = styled.div`
  background: #0a1c36;
  width: 100%;
  color: white;
  height: 50px;
  box-sizing: border-box;
  font-weight: 400;
  padding-top: 14px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

const Badge: React.FC<BadgeProps> = ({ type }) => {
  const badge = badges[type];
  const { src, text, style } = badge;
  const alt = src + ' Logo';

  return (
    <Container style={style.container}>
      <ImageContainer style={style.imageContainer}>
        <Image src={src} alt={alt} style={style.image}/>
      </ImageContainer>
      <TextContainer style={style.textContainer}>
        {text}
      </TextContainer>
    </Container>
  );
}

export default Badge;