import React, { useState } from 'react';
import { Route, Switch } from 'react-router';
import styled from 'styled-components';

import Home from 'containers/Home';
import About from 'components/About';
import Projects from 'containers/Projects';
import Navbar from 'components/Navbar';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'global.css';
import Contact from 'components/Contact';
import { AnimatePresence } from 'framer-motion';

interface Props {

}

const Container = styled.div`
  background-color: var(--bg-main);
  width: 100vw;
  height: 100vh;
  color: white;
`

const Content = styled.div<{ size: number}>`
  height: calc(100vh - ${props => props.size}px);
  position: relative;
  padding: 2%;
`

const App: React.FC<Props> = () => {
  const [buttonSize, setButtonSize] = useState(window.innerWidth / 10);

  window.addEventListener('resize', () => {
    const size = Math.max(window.innerWidth / 10, 120);
    setButtonSize(size);
  });

  type Route = {
    path: string,
    component: React.FC<any>,
    exact?: boolean
  }

  const routes: Route[] = [
    { path: '/', component: Home, exact: true },
    { path: '/about', component: About },
    { path: '/projects', component: Projects },
    { path: '/contact', component: Contact },
  ]

  return (
    <Container>
      <AnimatePresence exitBeforeEnter>
        <Switch>
          <Content size={buttonSize}>
            {routes.map(route => (
              <Route key={route.path} exact={route.exact} path={route.path} component={route.component}/>
            ))}
          </Content>
        </Switch>
      </AnimatePresence>
      <Navbar size={buttonSize}/>
    </Container>
  );
}

export default App