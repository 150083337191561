import { BadgeStyles } from '.';

import nodeLogo from 'images/tech-logos/nodejs.png';
import postGresLogo from 'images/tech-logos/postgres.png';
import reactLogo from 'images/tech-logos/react.svg';
import sailsLogo from 'images/tech-logos/sails.png';
import signnowLogo from 'images/tech-logos/signnow.png';
import firebaseLogo from 'images/tech-logos/firebase.png';
import azureLogo from 'images/tech-logos/azure.png';
import dockerLogo from 'images/tech-logos/docker.png';
import canvasLogo from 'images/tech-logos/canvas.png';

interface BadgeInfo {
  text: string,
  style: BadgeStyles,
  src: string,
}

export const badges: { [key: string]: BadgeInfo } = {
  'react': {
    text: 'React JS',
    style: {
      image: { width: '100px' }
    },
    src: reactLogo,
  },
  'postgres': {
    text: 'PostqreSQL',
    style: {},
    src: postGresLogo,
  },
  'sails': {
    text: 'Sails JS',
    style: {},
    src: sailsLogo,
  },
  'nodejs': {
    text: 'Node JS',
    style: {},
    src: nodeLogo,
  },
  'signnow': {
    text: 'SignNow',
    style: {},
    src: signnowLogo,
  },
  'firebase': {
    text: 'Firebase',
    style: {},
    src: firebaseLogo,
  },
  'docker': {
    text: 'Docker',
    style: {},
    src: dockerLogo,
  },
  'azure': {
    text: 'Azure',
    style: {},
    src: azureLogo,
  },
  'canvas': {
    text: 'Canvas',
    style: {},
    src: canvasLogo,
  }
}