import { useIsMobile } from 'hooks/mobile';
import { about } from 'images/svgs/about';
import { contact } from 'images/svgs/contact';
import { projects } from 'images/svgs/projects';
import React, { useEffect, useState }  from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

interface Props {
  size: number,
}

interface BlockButtonAttributes {
  text: string,
  path: string,
  action: () => any,
  icon?: JSX.Element,
}

interface ButtonStyleAttributes {
  size: number, 
  content: string, 
  active: boolean, 
  first: boolean, 
}

const transparent = 'left bottom';
const white = 'right bottom';

const SlideButton = styled.button<ButtonStyleAttributes>`
  width: ${props => props.size + 'px'};
  height: ${props => Math.floor(props.size * 4 / 5) + 'px'};
  background: transparent;
  color: white;
  border: none;
  position: relative;
  cursor: pointer;
  background: linear-gradient(to right, transparent 50%, white 50%);
  background-size: 200% 100%;
  background-position: ${props => props.active ? white : transparent};
  transform: skew(-20deg);
  transition: 0.3s background-position ease-in-out;
  border-right: 2px solid white;
  border-left: ${props => props.first ? '2px solid white' : ''};
  
  &:after {
    content: ${props => `'${props.content}'`};
    position: absolute;
    background: linear-gradient(to right, white 50%, var(--bg-main) 50%);
    background-size: 200% 100%;
    background-position: ${props => props.active ? white : transparent};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) skew(20deg);
    font-size: ${props => (props.size * 0.15) + 'px'};
    transition: 0.3s background ease-in-out;
    font-family: 'Raleway', sans-serif;
  }

  &:hover {
    background-position: right bottom;
  }
  
  &:hover::after {
    color: var(--bg-main);
    background-position: right bottom;
  }
`

const IconButton = styled.button<{ isActive: boolean}>`
  height: 100%;
  width: 100%;
  font-weight: ${props => props.isActive ? 600 : 400};

  &:hover {
    cursor: ${props => props.isActive ? 'default' : 'pointer'}
  }

  span {
    padding: 4px;
    border-bottom: ${props => props.isActive ? '2px solid var(--bg-main)' : 'none' };
  }
  
  &:hover svg {
    fill: ${props => props.isActive ? 'black' : 'var(--bg-main)'};
  }

  &:hover path {
    stroke: ${props => props.isActive ? 'black' : 'var(--bg-main)'};
  }

  &:hover {
    color: ${props => props.isActive ? 'black' : 'var(--bg-main)'};
  }
`

const Icon = styled.div`
`

const Navbar: React.FC<Props> = ({ size }) => {
  const buttons: Array<BlockButtonAttributes> = [
    { 
      text: 'About', 
      path:'/about', 
      action: () => goto('/about'),
      icon: about
    },
    { 
      text: 'Projects', 
      path:'/projects', 
      action: () => goto('/projects'),
      icon: projects
    },
    { 
      text: 'Contact', 
      path:'/contact', 
      action: () => goto('/contact'),
      icon: contact
    },
  ]
  
  const history = useHistory();
  const location = useLocation();
  const isMobile = useIsMobile();

  const [buttonTop, setButtonTop] = useState(location.pathname === '/' ? '50%' : '100%');

  useEffect(() => {
    if (location.pathname !== '/') {
      setButtonTop('100%');
    } else {
      setButtonTop('50%');
    }
  }, [ location.pathname ]);

  const getPath = () => {
    const pathArray = location.pathname.split('/');
    return `/${pathArray[1]}`;
  }

  const goto = (location: string) => {
    history.push(location);
  }

  const lookingAtProject = location.pathname.includes('/projects/');

  return (
    !isMobile ? 
      <div
        className="centered"
        style={{
          top: buttonTop,
          transform: `translate(calc(-50% - 10px), ${buttonTop === '100%' ? -110 : -50}%`,
          transition: 'top 0.3s ease-in-out, transform 0.3s ease-in-out',
          zIndex: lookingAtProject ? -5 : 1,
          width: size * 3,
        }}
      >
        {buttons.map((button, i) => (
          <SlideButton 
            onClick={button.action} 
            content={button.text} 
            size={size} 
            active={button.path === getPath()}
            key={button.path}
            first={i === 0}
          />
        ))}
      </div>
    :
      <div
        className="w-100 bg-white d-flex justify-content-around"
        style={{
          height: 80,
          position: 'absolute',
          bottom: 0,
          left: 0,
        }}
      >
        {buttons.map(button => (
          <IconButton
            isActive={button.path === getPath()}
            onClick={button.action}
            key={button.path}
          >
            <Icon>
              {button.icon}
            </Icon>
            <span>{button.text}</span>
          </IconButton>
        ))}
      </div>
  );
}

export default Navbar;