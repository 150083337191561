import { useEffect, useState } from 'react';

const maxSize = 768;

const useIsMobile = () => {
  const [ isMobile, setIsMobile ] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < maxSize);
    }

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, [])

  return isMobile;
}

export { useIsMobile };