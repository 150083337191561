import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import CSS from 'csstype';
import HighlightImage from 'components/HighlightImage';
import { useInView } from "react-intersection-observer";
import { motion, useAnimation, Variants } from "framer-motion";

export interface SlideProps {
  name: string,
  text: JSX.Element,
  image: string,
  style?: CSS.Properties;
}

interface Props {
  data: SlideProps;
  selectImage: (image: string) => void;
  flip: boolean;
}

const Container = styled.div`
  width: 100%;
  min-height: ${window.innerHeight * 0.55}px;
  text-align: left;
  font-size: calc(18px + (28 - 18) * ((100vw - 300px) / (1600 - 300)));

  & h2 {
    font-size: calc(24px + (34 - 24) * ((100vw - 300px) / (1600 - 300)));
    font-weight: bold;
  }
`

const Slide: React.FC<Props> = ({ data, selectImage, flip=false }) => {
  const { name, text, image, style } = data;
  const [ flexDirection, setFlexDirection ] = useState('row');
  const [ textAlign, setTextAlign ] = useState('left');

  const updateStyle = useCallback(() => {
    let direction = 'row';
    let align = 'left';
    if (window.innerWidth < 768) {
      direction = 'column';
      align = 'center';
    }

    if (flip) {
      direction += '-reverse';
    }

    setFlexDirection(direction);
    setTextAlign(align);
  }, [ setFlexDirection, setTextAlign, flip ])

  useEffect(() => {
    updateStyle();
  }, [ updateStyle ]);

  useEffect(() => {
    window.addEventListener('resize', updateStyle)

    return () => window.removeEventListener('resize', updateStyle)
  }, [ flip, updateStyle ]);

  const fromAbove = useAnimation();
  const fromBelow = useAnimation();
  const [ ref1, inView1 ] = useInView({ threshold: 0.1 });
  const [ ref2, inView2 ] = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inView1) {
      fromAbove.start("visible");
    } else {
      fromAbove.start("hidden");
    }
  }, [ fromAbove, inView1 ]);

  useEffect(() => {
    if (inView2) {
      fromBelow.start("visible");
    } else {
      fromBelow.start("hidden");
    }
  }, [ fromBelow, inView2 ]);

  const aboveVariants: Variants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, delayChilden: 0.2, staggerChildren: 0.1 },
    },
    hidden: {
      y: -40,
      opacity: 0,
    },
  }

  const belowVariants: Variants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { delay: 0.1, duration: 0.5, delayChilden: 0.2, staggerChildren: 0.1 },
    },
    hidden: {
      y: 40,
      opacity: 0,
    },
  }

  return (
    <Container 
      className="d-flex justify-content-center align-items-center mx-0 py-5 px-5" 
      style={{
        ...style,
        flexDirection: flexDirection as any,
        textAlign: textAlign as any,
      }}
    >
      <motion.div 
        className={textAlign === 'left' ? 'px-5' : 'py-5'}
        ref={ref1}
        animate={fromBelow}
        initial="hidden"
        variants={belowVariants}        
      >
        {text}
      </motion.div>
      <div
        className="mt-5"
      >
        <motion.div
          ref={ref2}
          animate={fromAbove}
          initial="hidden"
          variants={aboveVariants}
        >
          <HighlightImage
            src={image}
            alt={`${name} image`}
            select={() => selectImage(image)}
          />
        </motion.div>
      </div>
    </Container>
  );
}

export default Slide