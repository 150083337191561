import React, { useState } from 'react';
import CSS from 'csstype';
import Modal from 'react-modal';

import styled from 'styled-components';
import Badge from 'components/Badge';
import Slide, { SlideProps } from 'containers/Slide';
import { useIsMobile } from 'hooks/mobile';

interface Props {
  logo: string,
  slides: SlideProps[],
  techs: string[],
  style?: CSS.Properties,
}

const CloseButtonContainer = styled.div`
  background: transparent;
  border: none;
  outline: none !important;
  font-size: 40px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  position: absolute;
  top: 0px;
  right: 20px;
`
const CloseButton = styled.button`
  background: transparent;
  font-weight: 600;

  :hover {
    font-weight: 800;
  }
`

const ProjectCard: React.FC<Props> = ({ logo, slides, techs=[], style={} }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const isMobile = useIsMobile();

  const select = (image: string) => {
    setModalOpen(true);
    setModalImage(image);
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;    
  }

  const closeModal = () => {
    setModalOpen(false);
    document.body.style.position = '';
    document.body.style.top = '';
  }

  return (
    <div className="text-dark mx-auto" style={style}>
      <div className="h-100 pb-5 text-center">
        <div className="bg-white pt-3 pb-5 w-100" style={{ position: 'sticky', top: 0, zIndex: 3, width: '50%' }}>
          <img 
            className="mt-4" 
            src={logo} 
            alt="ProjectCard logo" 
            style={{ maxWidth: '100%' }} 
          />
        </div>
        <div className="row justify-content-center">
          <div className="col">
            <h2>Tech Used</h2>
            <div>
              {techs.map(badge => <Badge key={badge} type={badge} />)}
            </div>
          </div>
        </div>
        <div>
          {slides.map((slide, i) => (
            <Slide 
              data={slide}
              selectImage={() => select(slide.image)}
              flip={i % 2 === 1}
              key={`slide-${i}`}
            />
          ))} 
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        style={styles.modal}
        closeTimeoutMS={500}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      > 
        <div className={isMobile ? "px-1" : "px-5" } style={imageStyle}>
          <div style={{ position: 'relative' }}>
            <CloseButtonContainer>
              <CloseButton onClick={closeModal}>×</CloseButton>
            </CloseButtonContainer>
            <img src={modalImage} alt="modal" style={{ width: '100%', borderRadius: '5px' }}/>
          </div>
        </div>
      </Modal>
    </div>
  )
}

type ModalStyleTypes = { [key: string]: CSS.Properties }

const styles: { modal: ModalStyleTypes } = {
  modal: {
    overlay: { 
      background: 'rgba(0, 0, 0, 0.8)', 
      zIndex: 20,
    },
    content: {
      background: 'transparent',
      border: 'none',
      padding: '0px',
      width: '100%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    }
  }
}

const imageStyle: CSS.Properties = {
  boxShadow: 'var(--shadow-4)',
}

export default ProjectCard;