import { ProjectAttributes } from 'containers/Projects';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { device } from 'utils/device';

interface Props {
  projects: ProjectAttributes[];
  index: number;
  controls: {
    next: () => any;
    prev: () => any;
    toggle: () => any;
  },
  animProps: {
    isOpen: boolean;
    isOpening: boolean;
    isClosing: boolean;
    onAnimationEnd: () => any;
  }
}

const grow = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`

const shrink = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
`

const offLeft = keyframes`
  from {
    transform: translateX(0%);
    display: block;
  }

  to {
    transform: translateX(-100%);
    display: hidden;
  }
`

const offRight = keyframes`
  from {
    transform: translateX(0%);
    display: block;
  }

  to {
    transform: translateX(100%);
    display: hidden;
  }
`

const fromLeft = keyframes`
  from {
    transform: translateX(-100%);
    display: hidden;
  }

  to {
    transform: translateX(0%);
    display: block;
  }
`

const fromRight = keyframes`
  from {
    transform: translateX(100%);
    display: hidden;
  }

  to {
    transform: translateX(0%);
    display: block;
  }
`

type ContainerProps = { 
  isOpening: boolean, 
  isOpen: boolean, 
  isClosing: boolean 
}

const getContainer = (props: ContainerProps) => {
  if (props.isOpening) {
    return css`${grow} 0.3s ease-in-out`
   } else if (props.isClosing) {
    return css`${shrink} 0.3s ease-in-out`
  } 
  
  return '';
}

const Container = styled.div<ContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  visibility: ${props => props.isOpen ? 'visible' : 'hidden' };
  background: white;
  animation: ${props => getContainer(props)};
  z-index: 10;
`

const ControlsContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 5;
  width: 100%;
  pointer-events: none;

  @media ${device.tablet} {
    height: ${window.innerHeight}px;
  }
`

const controlBase = `
  position: absolute;
  top: 3rem;
  cursor: pointer;
  z-index: 3;
  transition: 0.3s top ease-in-out, 0.3s left ease-in-out, 0.3s right ease-in-out;
  pointer-events: all;

  &:after {
    content: '';
    border-radius: 24px;
    background: var(--bg-light);
    width: 0px;
    height: 0px;
    position: absolute;
    visibility: hidden;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    transition: 0.2s all ease-in-out;
  }
`

const controlHover = `
  &:hover::after {
    width: 48px;
    height: 48px;
    display: block;
    visibility: visible;
  }
`

const PrevContainer = styled.div`
  ${controlBase}
  left: 8rem;

  ${controlHover}

  @media ${device.tablet} {
    left: 0rem;
    top: 50%;
    transform: translateY(-50%);
  }
`

const NextContainer = styled.div`
  ${controlBase}
  right: 8rem;

  ${controlHover}  

  @media ${device.tablet} {
    right: 0rem;
    top: 50%;
    transform: translateY(-50%);
  }
`

const CloseContainer = styled.div`
  ${controlBase}
  top: 0.5rem;
  right: 1rem;

  ${controlHover}
`

const ProjectsContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`

const iconSize = "3rem";
const iconColor = "var(--bg-dark)";

type ProjectContainerProps = { 
  isActive: boolean, 
  isOpen: boolean, 
  isLeaving: boolean, 
  isEntering: boolean, 
  direction: string 
}

const getAnimation = (props: ProjectContainerProps) => {
  const { isEntering, isLeaving, direction } = props;
  let result;

  if (isEntering) {
    if (direction === 'left') {
      result = fromLeft;
    } else {
      result = fromRight;
    }
  } else if (isLeaving) {
    if (direction === 'left') {
      result = offRight;
    } else {
      result = offLeft;
    }
  }  

  return css`${result} 0.3s ease-in-out`;
}

const ProjectContainer = styled.div<ProjectContainerProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  animation: ${props => getAnimation(props)};
  transition: ${props => (props.isLeaving || props.isEntering) ? '0.3s transform ease-in-out' : ''};
  display: ${props => (props.isActive || props.isLeaving || props.isEntering) ? 'block' : 'none'}
`

const ProjectView: React.FC<Props> = ({ projects, index, controls, animProps }) => {
  const { next, prev, toggle } = controls;
  const { isOpen, isOpening, isClosing, onAnimationEnd } = animProps;
  const [ leavingIndex, setLeavingIndex ] = useState<number>(-1);
  const [ enteringIndex, setEnteringIndex ] = useState<number>(-1);
  const [ direction, setDirection ] = useState<string>('left');

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ index, scrollRef ]);

  const nextProject = () => {
    const newIndex = (index + 1) % projects.length;
    goToIndex(newIndex);
    setDirection('right');
    next();
  }

  const prevProject = () => {
    let newIndex = (index - 1) % projects.length;
    if (newIndex < 0) newIndex += projects.length;
    goToIndex(newIndex);
    setDirection('left');
    prev();
  }

  const goToIndex = (i: number) => {
    setLeavingIndex(index);
    setEnteringIndex(i);
  }

  const onProjectAnimEnd = () => {
    setLeavingIndex(-1);
    setEnteringIndex(-1);
  }

  return (
    <Container 
      isOpen={isOpen}
      isOpening={isOpening} 
      isClosing={isClosing}
      onAnimationEnd={onAnimationEnd}
    >
      {isOpen &&
        <ControlsContainer>
          <PrevContainer onClick={prevProject}>
            <svg stroke="currentColor" fill={iconColor} strokeWidth="0" viewBox="0 0 512 512" height={iconSize} width={iconSize} xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>
          </PrevContainer>
          <NextContainer onClick={nextProject}>
            <svg stroke="currentColor" fill={iconColor} strokeWidth="0" viewBox="0 0 512 512" height={iconSize} width={iconSize} xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>
          </NextContainer>
          <CloseContainer onClick={toggle}>
            <svg stroke="currentColor" fill={iconColor} strokeWidth="0" viewBox="0 0 512 512" height={iconSize} width={iconSize} xmlns="http://www.w3.org/2000/svg"><path d="M278.6 256l68.2-68.2c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-68.2-68.2c-6.2-6.2-16.4-6.2-22.6 0-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3l68.2 68.2-68.2 68.2c-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3 6.2 6.2 16.4 6.2 22.6 0l68.2-68.2 68.2 68.2c6.2 6.2 16.4 6.2 22.6 0 6.2-6.2 6.2-16.4 0-22.6L278.6 256z"></path></svg>
          </CloseContainer>
        </ControlsContainer>
      }
      <ProjectsContainer>
        <div ref={scrollRef}/>
        {projects.map((p, i) => (
          <ProjectContainer 
            className={`project-${p.name}`}
            isActive={i === index} 
            isOpen={isOpen}
            key={p.name}
            onAnimationEnd={onProjectAnimEnd}
            isLeaving={i === leavingIndex}
            isEntering={i === enteringIndex}
            direction={direction}
          >
            {p.component(p.props)}
          </ProjectContainer>
        ))}
      </ProjectsContainer>
    </Container>
  );
}

export default ProjectView