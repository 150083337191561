import React from 'react';

import ProjectView from 'containers/ProjectView';

import main from 'images/sheepshead/main.jpg';
import logo from 'images/sheepshead/logo.png';
import { ProjectProps } from 'containers/Projects';

const slides = [
  {
    name: 'General',
    image: main,
    text: (
      <div>
        <h2>Exciting Multiplayer Card Game</h2>
          A feature multi-room card game to play my family's favorite game online together:
          Sheepshead.
      </div>
    ),
  },
  {
    name: 'UI/UX',
    image: main,
    text: (
      <div>
        <h2>Full Animation Library</h2>
        Used the Canvas API to create a dynamic and entertaining animation library for all card
        actions.
      </div>
    ),
    style: {
      background: 'var(--bg-darker)',
      color: 'white',
    }
  },
  {
    name: 'Design',
    image: main,
    text: (
      <div>
        <h2>Separate Front and Back Ends</h2>
        Back End game engine is completely separated from the front view, allowing for others to 
        create their own versions of the game.
      </div>
    ),
    style: {
      background: 'var(--bg-blue-muted)',
      color: 'white',
    }
  },
  {
    name: 'Feature',
    image: main,
    text: (
      <div>
        <h2>Real Time Socialization</h2>
        Utilized the Socket API to allow for real time chat, emotes and stat updates.
      </div>
    ),
    style: {

    }
  }
]
const badges = [ "react", "nodejs", "firebase", "canvas" ];

const Sheepshead: React.FC<ProjectProps> = (props) =>  (
  <ProjectView {...props} logo={logo} slides={slides} techs={badges}/>
)

export default Sheepshead;