import React from 'react';
import styled from 'styled-components';

interface Props {
  src: string,
  alt: string,
  select?: () => void
}

const Container = styled.div`
  width: 300px;
  display: inline-block;
  margin: 0 10px;

  > img {
    width: 100%;
    transition: transform 0.2s ease-in-out;
  }

  :hover :first-child {
    transform: translateY(-10px);
  }

  :hover :nth-child(2) {
    transform: scale(0.95, -0.95);
  }
`

const Main = styled.img`
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
`

const Reflection = styled.img`
  transform: scaleY(-1);
  -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  mask-image: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0) 75%);
`

const HighlightImage = ({ src, alt, select }: Props) => {
  return (
    <Container>
      <Main src={src} alt={alt} onClick={select} loading="lazy"/>
      <Reflection src={src} alt={alt + '-reflection'}/>
    </Container>
  )
}

export default HighlightImage;