import { ProjectProps } from "containers/Projects";
import ProjectView from "containers/ProjectView";
import React from "react";
import logo from "images/tile_editor/logo.png";
import main from "images/tile_editor/blank_slate.jpg";

const slides = [
  {
    name: "General",
    image: main,
    text: (
      <div>
        <h2>2D Tile Creation Made Simple</h2>
        Full suite editor to create and download feature rich tile maps for 2D
        games. Upload any image and select areas to designate as tiles to paint
        with.
      </div>
    ),
  },
  {
    name: "UI/UX",
    image: main,
    text: (
      <div>
        <h2>Powerful Tools</h2>
        Paint, flood fill, select fill, and more tools allow users to create any
        map they can imagine.
      </div>
    ),
    style: {
      background: "var(--bg-darker)",
      color: "white",
    },
  },
  {
    name: "Design",
    image: main,
    text: (
      <div>
        <h2>Layers Upon Layers</h2>
        Add up to 20 layers per map, selecting visibility settings or
        designating a layer as a trigger layer - useable for events in the game.
      </div>
    ),
    style: {
      background: "var(--bg-blue-muted)",
      color: "white",
    },
  },
  {
    name: "Feature",
    image: main,
    text: (
      <div>
        <h2>Take it With You</h2>
        Download your project to use in your own game or upload a previous
        creation to continue creating.
      </div>
    ),
    style: {},
  },
];
const badges = ["react", "nodejs", "canvas"];
const TileEditor: React.FC<ProjectProps> = (props) => (
  <ProjectView {...props} logo={logo} slides={slides} techs={badges} />
);

export default TileEditor;
