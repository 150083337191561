import { GAME_HEIGHT, GAME_WIDTH } from "containers/GameModal/constants";

import KeyHandler from "./KeyHandler";
import Player from "./Player";
import State from "./State";
import Wall from "./Wall";
import bgMusic from "./music/bg_music.mp3";
import firebase from "firebase";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "portfoilio-site.firebaseapp.com",
  projectId: "portfoilio-site",
};
firebase.initializeApp(config);

const database = firebase.firestore();

const PlayState = new State();
PlayState.update = (game: Game) => {
  game.player.update(game.keys);

  const toRemove: Array<number> = [];
  game.walls.forEach((wall, i) => {
    wall.y += (game.wallSpeed + (game.timer / 1000) * 10) * 0.016;

    if (game.player.collidesWith(wall)) {
      game.end();
    }

    if (!wall.scored && wall.y > GAME_HEIGHT - 10) {
      game.score += 5;
      wall.scored = true;
    }

    if (wall.y > GAME_HEIGHT) {
      toRemove.push(i);
    }
  });

  toRemove.reverse().forEach((num) => {
    game.walls.splice(num, 1);
  });

  const wallCd = 4000 / (1 + Math.exp((game.timer - 1000) / 10000)) + 500;
  if (game.lastWall > wallCd) {
    const gap = Math.floor(Math.random() * 40 + 60);
    const gapStart = Math.floor(Math.random() * 400 + 100);

    game.walls.push(
      new Wall(0, gapStart),
      new Wall(gapStart + gap, GAME_WIDTH - (gapStart + gap))
    );

    game.lastWall = 0;
  } else {
    game.lastWall += 16;
  }
};
PlayState.render = (game: Game) => {
  const ctx = game.canvas.getContext("2d");

  if (ctx) {
    ctx.fillStyle = "rgba(31, 29, 29, 0.5)";
    ctx.fillRect(0, 0, game.canvas.width, game.canvas.height);

    game.walls.forEach((wall) => {
      ctx.fillStyle = "rgb(0, 217, 255)";
      ctx.fillRect(wall.x, wall.y, wall.width, wall.height);
    });

    ctx.fillStyle = "rgb(252, 177, 3)";
    ctx.fillRect(
      game.player.left,
      game.player.top,
      game.player.width,
      game.player.height
    );

    ctx.fillStyle = "white";
    ctx.textAlign = "left";
    ctx.textBaseline = "top";
    ctx.font = "20px Patrick Hand";
    ctx.fillText(`Score: ${game.score}`, 10, 10);
  }
};

const MenuState = new State();
MenuState.update = (game: Game) => {
  if (game.keys.getKey(" ")) {
    game.reset();
  }

  if (game.zoom < 1.5) {
    game.zoom += 0.05;
  }
};
MenuState.render = (game: Game) => {
  const ctx = game.canvas.getContext("2d");

  if (ctx) {
    ctx.fillStyle = "rgba(0, 0, 0, 0.8)";
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    ctx.fillStyle = "white";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    ctx.save();

    ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2 - 70);
    ctx.scale(game.zoom, game.zoom);
    ctx.font = "80px Modak";
    ctx.fillText("Wall Dodger", 0, 0);

    ctx.font = "30px Patrick Hand";
    ctx.fillText("Press Spacebar to Start!", 0, 60);
    ctx.fillText("Press P to Play/ Pause Music", 0, 100);

    ctx.font = "20px Patrick Hand";
    ctx.fillText(`Your High Score: ${game.localHighScore}`, 0, 160);

    ctx.font = "20px Patrick Hand";
    ctx.fillText(`High Score: ${game.highScore}`, 0, 190);

    ctx.restore();

    ctx.font = "14px Patrick Hand";
    ctx.textAlign = "right";
    ctx.fillText(
      "Music Credit: https://www.FesliyanStudios.com Background Music",
      ctx.canvas.width - 10,
      ctx.canvas.height - 20
    );
  }
};

class Game {
  canvas: HTMLCanvasElement;

  player: Player;
  walls: Array<Wall>;
  lastWall: number;
  wallSpeed: number;

  score: number;
  localHighScore: number;
  highScore: number;
  timer: number;
  playing: boolean;

  playState: State;
  menuState: State;

  keys: KeyHandler;
  audio: HTMLAudioElement;

  zoom: number;

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.player = new Player();
    this.score = 0;
    this.localHighScore = parseInt(
      window.localStorage.getItem("highScore") || "0"
    );
    this.highScore = 0;

    database
      .collection("game")
      .doc("highScore")
      .get()
      .then((doc) => (doc.data() ? (this.highScore = doc.data()?.value) : 0));
    this.timer = 0;

    this.walls = [];
    this.lastWall = Infinity;
    this.wallSpeed = 200;

    this.playState = PlayState;
    this.menuState = MenuState;

    this.playing = false;

    this.keys = new KeyHandler();

    this.keys.addCallback("keyup", (key) => {
      if (key === "p") {
        if (this.audio.paused) {
          this.audio.play();
          window.localStorage.setItem("autoPlay", "true");
        } else {
          this.audio.pause();
          window.localStorage.setItem("autoPlay", "false");
        }
      }
    });

    this.audio = new Audio(bgMusic);
    this.audio.loop = true;
    this.audio.addEventListener("canplaythrough", () => {
      if (
        !window.localStorage.getItem("autoPlay") ||
        window.localStorage.getItem("autoPlay") === "true"
      )
        this.audio.play();
    });

    this.zoom = 0;

    this.run();
  }

  end() {
    if (this.score > this.localHighScore) {
      this.localHighScore = this.score;
      window.localStorage.setItem("highScore", this.localHighScore.toString());
    }

    if (this.score > this.highScore) {
      database
        .collection("game")
        .doc("highScore")
        .update({
          value: this.score,
          updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
        });
      this.highScore = this.score;
    }

    this.playing = false;
  }

  reset() {
    this.player.x = this.canvas.width / 2;
    this.player.vx = 0;
    this.walls = [];
    this.timer = 0;
    this.score = 0;
    this.lastWall = Infinity;
    this.playing = true;
  }

  run() {
    this.init();
    this.loop();
  }

  init() {
    const rect = this.canvas.parentElement?.getBoundingClientRect();
    if (rect) {
      this.canvas.width = rect.width;
      this.canvas.height = rect.height;
    }
  }

  loop() {
    if (this.playing) {
      this.playState.update(this);
    } else {
      this.menuState.update(this);
    }

    this.playState.render(this);

    if (!this.playing) {
      this.menuState.render(this);
    }

    this.timer += 16;

    window.requestAnimationFrame(this.loop.bind(this));
  }
}

export default Game;
