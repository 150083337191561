import "./index.css";

import React, { useEffect, useState } from "react";

import CSS from "csstype";
import FadeInOut from "animations/FadeInOut";
import GameModal from "containers/GameModal";
import MTC from "projects/MTC";
import ProjectView from "containers/ProjectContainer";
import { RouteComponentProps } from "react-router";
import Sheepshead from "projects/Sheepshead";
import TileEditor from "projects/TileEditor";
import { device } from "utils/device";
import mtc_logo from "images/mtc/icon.png";
import sheep_logo from "images/sheepshead/sheep.png";
import styled from "styled-components";
import tile_logo from "images/tile_editor/tile.png";
import { useIsMobile } from "hooks/mobile";

export interface ProjectAttributes {
  name: string;
  tag: string;
  path: string;
  component: React.FunctionComponent<ProjectProps>;
  logo: string;
  style?: CSS.Properties;
  props: {};
}

const GameContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
  background: white;
  padding: 10px 14px;
  color: var(--bg-main);
  box-shadow: var(--shadow-3);
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  font-size: 16px;

  :hover {
    box-shadow: var(--shadow-5);
    font-size: 20px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
`;

const CardsContainer = styled.div<{ isClosing: boolean }>`
  opacity: ${(props) => (props.isClosing ? 0 : 1)};
  transform: ${(props) => (props.isClosing ? "scale(0)" : "scale(1)")};
  transition: 0.3s transform ease-in-out, 0.3s opacity ease-in-out;
`;

const Card = styled.div`
  width: 180px;
  height: 260px;
  background: transparent;
  color: black;
  margin: 10px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5%);
  }

  @media ${device.mobileL} {
    width: 100%;
    height: 200px;
  }
`;

const CardContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transform-style: preserve-3d;
  box-shadow: var(--shadow-3);
  cursor: pointer;
  background-color: white;
`;

const Icon = styled.img`
  width: 50%;
  display: block;
  margin: 0 auto;

  @media ${device.mobileL} {
    width: 50px;
    height: 50px;
  }
`;

export interface ProjectProps {
  style?: CSS.Properties;
}

const projects: Array<ProjectAttributes> = [
  {
    name: "MTC",
    tag: "Professional Tool",
    path: "/projects/mtc",
    component: MTC,
    logo: mtc_logo,
    props: {},
  },
  {
    name: "Sheepshead",
    tag: "Gaming",
    path: "/projects/sheepshead",
    component: Sheepshead,
    logo: sheep_logo,
    props: {},
  },
  {
    name: "Tile Editor",
    tag: "Art and Creation",
    path: "/projects/tile-editor",
    component: TileEditor,
    logo: tile_logo,
    props: {},
  },
];

const Projects: React.FC<RouteComponentProps> = ({ history, location }) => {
  const { pathname } = location;
  const [project, setProject] =
    useState<ProjectAttributes | undefined>(undefined);
  const [index, setIndex] = useState<number>(-1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpening, setIsOpening] = useState<boolean>(false);
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const [showGame, setShowGame] = useState<boolean>(false);

  const isMobile = useIsMobile();

  useEffect(() => {
    const openProject = projects.find((p) => p.path === pathname);

    if (openProject !== undefined) {
      setProject(openProject);
      setIndex(projects.findIndex((p) => p.name === openProject.name));
      setIsOpen(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (project?.name) {
      history.push(project.path);
    } else {
      history.push("/projects");
    }
  }, [project, history]);

  const openProject = (p: ProjectAttributes, i: number) => () => {
    setProject(p);
    setIndex(i);
    setIsOpening(true);
    setIsClosing(false);
  };

  const closeProject = () => {
    setIndex(-1);
    setProject(undefined);
    setIsOpen(false);
    setIsOpening(false);
    setIsClosing(true);
  };

  const next = () => {
    const newIndex = (index + 1) % projects.length;
    setIndex(newIndex);
    setProject(projects[newIndex]);
  };

  const prev = () => {
    let newIndex = (index - 1) % projects.length;
    if (newIndex < 0) newIndex += projects.length;
    setIndex(newIndex);
    setProject(projects[newIndex]);
  };

  const onAnimationEnd = () => {
    if (project) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }

    if (isClosing) {
      setProject(undefined);
    }

    setIsOpening(false);
    setIsClosing(false);
  };

  return (
    <Container>
      <FadeInOut>
        <ProjectView
          projects={projects}
          index={index}
          controls={{
            next,
            prev,
            toggle: closeProject,
          }}
          animProps={{
            isOpening,
            isClosing,
            isOpen,
            onAnimationEnd,
          }}
        />
        <CardsContainer
          className="row w-100 justify-content-center mx-0"
          isClosing={isOpening || isOpen}
        >
          {projects.map((p, i) => (
            <Card
              key={p.name}
              onClick={openProject(p, i)}
              onAnimationEnd={onAnimationEnd}
            >
              <CardContent className="card-content py-5">
                <Icon src={p.logo} alt={`${p.name}-logo`} />
                <h4 className="mt-3">{p.name}</h4>
                <div className="font-italic">{p.tag}</div>
              </CardContent>
            </Card>
          ))}
        </CardsContainer>
        {!isMobile && (
          <GameContainer onClick={() => setShowGame(true)}>
            Bored?
          </GameContainer>
        )}
        {showGame && <GameModal />}
      </FadeInOut>
    </Container>
  );
};

export default Projects;
