import { GAME_HEIGHT, GAME_WIDTH } from "containers/GameModal/constants";

import KeyHandler from "./KeyHandler";
import Wall from "./Wall";

export default class Player {
  x: number;
  y: number;
  width: number;
  height: number;

  acc: number;
  vx: number;

  constructor() {
    this.x = GAME_WIDTH / 2;
    this.y = GAME_HEIGHT - 30;
    this.width = 40;
    this.height = 40;

    this.acc = 40;
    this.vx = 0;
  }

  get left() {
    return this.x - this.width / 2;
  }
  get right() {
    return this.x + this.width / 2;
  }
  get top() {
    return this.y - this.height / 2;
  }
  get bot() {
    return this.y + this.height / 2;
  }

  collidesWith(wall: Wall) {
    return !(
      this.left > wall.right ||
      this.right < wall.left ||
      this.top > wall.bot ||
      this.bot < wall.top
    );
  }

  update(keys: KeyHandler) {
    if (keys.getKey("ArrowLeft")) {
      this.vx -= this.acc * 0.16;
    } else if (keys.getKey("ArrowRight")) {
      this.vx += this.acc * 0.16;
    }

    this.x += this.vx * 0.16;
    this.vx *= 0.85;
  }
}
