import FadeInOut from 'animations/FadeInOut';
import React, { useReducer } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { device } from 'utils/device';
import emailjs from 'emailjs-com';

import './index.css';
import './index.scss';
import reducer, { initialState, ActionTypes } from './reducer';

interface Props {

}

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 600px;
  max-height: 90%;
  background: white;
  overflow: auto;
  box-shadow: var(--shadow-3);

  @media ${device.tablet} {
    width: 100%;
    min-width: auto;
  }
`

const MaterialInput = styled.div<{ label: string, value: string, error?: boolean }>`
  position: relative;
  font-size: 18px;

  & input, & textarea {
    border: ${props => props.error ? '1px solid red' : '1px solid grey'};
    transition: 0.3s all ease-in-out;
  }

  &:after {
    content: ${props => `"${props.label}"`};
    position: absolute;
    left: 6px;
    transition: 0.3s all ease-in-out;
    background: white;
    top: ${props => props.value ? '-10px' : '8px'};
    font-size: ${props => props.value ? '14px' : '18px'};
    padding: 0px 6px;
    color: ${props => props.error ? 'red' : 'black'};
    font-weight: bold;
    text-transform: uppercase;
  }

  &:focus-within::after {
    top: -10px;
    font-size: 14px;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`

const Contact: React.FC<Props> = () => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { 
    name, email, message, sending, success, error, nameError, emailError, messageError
  } = state;

  const handleChange = (type: ActionTypes) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch({ type, payload: e.target.value });
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (sending) return;

    if (!name || !email || !message) {
      if (!name) {
        dispatch({ type: 'FORM_ERROR', payload: 'nameError' });
      }

      if (!email) {
        dispatch({ type: 'FORM_ERROR', payload: 'emailError' });
      }

      if (!message) {
        dispatch({ type: 'FORM_ERROR', payload: 'messageError' });
      }

      return;
    }

    dispatch({ type: 'SUBMIT', payload: '' });

    emailjs.send('service_64qvt4l', 'template_3od8uwp', {
      from_name: name,
      reply_to: email,
      message
    }, 'user_dgBcfi0A4AknTbS8YjWNV')
    .then(res => {
      dispatch({ type: 'SUCCESS', payload: '' });
    })
    .catch(err => {
      dispatch({ type: 'ERROR', payload: err.text });
    })
  }

  const renderOverlay = () => {
    const textStyle:CSSProperties = { fontSize: '2rem', fontWeight: 'bold', margin: '0.75rem 0' };

    if (sending) {
      return (
        <>
          <div className='loader'>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__ball"></div>
          </div>
          <div style={textStyle}>
            Sending...
          </div>
        </>
      )
    }

    if (success) {
      return (
        <div className="success-checkmark">
          <div className="check-icon">
            <span className="icon-line line-tip"></span>
            <span className="icon-line line-long"></span>
            <div className="icon-circle"></div>
            <div className="icon-fix"></div>
          </div>
          <div style={textStyle}>
            Message Sent!
          </div>
          <button className="btn btn-primary" onClick={() => dispatch({ type: 'RESET', payload: '' })}>
            Great!
          </button>
        </div>
      )
    }

    if (error) {
      return  (
        <div className="error-x">
          <div className="error-icon">
            <span className="error-line error-line-left"></span>
            <span className="error-line error-line-right"></span>
            <div className="error-circle"></div>
          </div>
          <div style={textStyle}>
            {error}
          </div>
          <button className="btn btn-primary" onClick={() => dispatch({ type: 'RESET', payload: '' })}>
            Try Again
          </button>
        </div>
      )
    }

    dispatch({ type: 'RESET', payload: '' })
    return (
      <div></div>
    )
  }

  return (
    <Container>
      <FadeInOut>
        <form className="p-4" onSubmit={handleSubmit}>
          <h2 className="mb-3" style={{ color: 'black' }}>Contact</h2>
          <MaterialInput label="Name*" value={name} error={nameError}>
            <input
              value={name}
              onChange={handleChange('UPDATE_NAME')}
              className="px-3 py-2 mb-4 custom-input"
            />
          </MaterialInput>
          <MaterialInput label="Email*" value={email} error={emailError}>
            <input
              value={email}
              onChange={handleChange('UPDATE_EMAIL')}
              className="px-3 py-2 mb-4 custom-input"
            />
          </MaterialInput>
          <MaterialInput label="Message*" value={message} error={messageError}>
            <textarea
              value={message}
              onChange={handleChange('UPDATE_MESSAGE')}
              className="p-3 mb-4 custom-input"
              style={{ height: 200 }}
            />
          </MaterialInput>
          <button 
            disabled={nameError || emailError || messageError || sending}
            className="btn btn-primary"
            type="submit"
          >
            Submit
          </button>
          {(sending || success || error) &&
            <Overlay className="d-flex flex-column justify-content-center align-items-center">
              {renderOverlay()}
            </Overlay>
          }
        </form>
      </FadeInOut>
    </Container>
  );
}

export default Contact;