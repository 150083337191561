import React from 'react';

const Home = () => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '70%' }}>
      <h1 className="headline centered" style={{ 
        letterSpacing: 4,
        fontSize: 'calc(38px + (150 - 38) * ((100vw - 300px) / (1600 - 300)))',
        whiteSpace: 'nowrap',
        fontWeight: 300,
      }}>
      Daniel Cardenas
      </h1>
    </div>
  )
}

export default Home;