type FormState = {
  name: string,
  email: string,
  message: string,
  sending: boolean,
  success: boolean,
  error: string,
  nameError: boolean,
  emailError: boolean,
  messageError: boolean,
}

type ActionTypes =
  | 'UPDATE_NAME'
  | 'UPDATE_EMAIL'
  | 'UPDATE_MESSAGE'
  | 'SUBMIT'
  | 'SUCCESS'
  | 'CLEAR_SUCCESS'
  | 'RESET'
  | 'ERROR'

type ErrorTypes = 
  | 'nameError'
  | 'emailError'
  | 'messageError'

type Action =
  | { type: ActionTypes, payload: string }
  | { type: 'FORM_ERROR', payload: ErrorTypes }


const initialState: FormState = {
  name: '',
  email: '',
  message: '',
  sending: false,
  success: false,
  error: '',
  nameError: false,
  emailError: false,
  messageError: false,
}

const reducer = (state: FormState, action: Action) => {
  switch (action.type) {
    case 'UPDATE_NAME':
      return {
        ...state,
        name: action.payload,
        nameError: false
      }
      case 'UPDATE_EMAIL':
        return {
        ...state,
        email: action.payload,
        emailError: false
      }
    case 'UPDATE_MESSAGE':
      return {
        ...state,
        message: action.payload,
        messageError: false
      }
    case 'SUBMIT':
      return {
        ...state,
        sending: true,
        success: false,
        error: '',
      }
    case 'SUCCESS':
      return {
        ...state,
        name: '',
        email: '',
        message: '',
        sending: false,
        success: true,
        error: ''
      }
    case 'CLEAR_SUCCESS':
      return {
        ...state,
        success: false,
      }
    case 'RESET':
      return initialState
    case 'ERROR':
      return {
        ...state,
        sending: false,
        success: false,
        error: action.payload
      }
    case 'FORM_ERROR':
      return {
        ...state,
        [action.payload]: true
      }
    default:
      return state;
  }
}

export default reducer;
export {
  initialState
}
export type {
  ActionTypes
}