export default class KeyHandler {
  keys: Map<string, boolean>;
  callbacks: Map<string, Array<Callback>>;

  constructor() {
    this.keys = new Map<string, boolean>();
    this.callbacks = new Map<string, Array<Callback>>();

    window.addEventListener("keydown", this.handleEvent.bind(this));
    window.addEventListener("keyup", this.handleEvent.bind(this));

    this.callbacks.set("keydown", []);
    this.callbacks.set("keyup", []);
  }

  getKey(value: string) {
    return this.keys.get(value);
  }

  handleEvent(e: KeyboardEvent) {
    const { key, type } = e;

    this.keys.set(key, type === "keydown" ? true : false);
    const callbacks = this.callbacks.get(type);

    if (callbacks) {
      callbacks.forEach((cb) => cb(key));
    }
  }

  addCallback(type: string, cb: Callback) {
    this.callbacks.get(type)?.push(cb);
  }
}
